<template>
    <div class="attachment" @click.stop="handleOpenAttachment">
        <span class="filename">
            <i v-if="loading" class="el-icon-loading" />
            <i v-else class="el-icon-document" />
            {{ name }}
        </span>
        <el-popconfirm
            title="Delete this attachment?"
            @confirm="handleDeleteAttachment"
        >
            <el-button
                v-if="!readonly && is_superadmin"
                slot="reference"
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click.stop
            />
        </el-popconfirm>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import auth from '@/utils/auth';

export default {
    name: 'attachment',
    props: {
        name: {
            type: String,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        is_superadmin() {
            return auth.role === auth.ROLE_SUPERADMIN;
        },
    },
    watch: {},
    methods: {
        async handleOpenAttachment() {
            this.loading = true;
            const token = await firebase.auth().currentUser.getIdToken();
            const data = {
                file: this.path,
            };
            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_BASE_URI}/download`,
                    data,
                    {
                        headers: {Authorization: `Bearer ${token}`},
                    }
                );
                const url = response.data?.data?.url;
                window.open(url, '_blank');
            } catch (e) {
                console.log('Error getting URL:', e);
                Sentry.captureException(e);
            } finally {
                this.loading = false;
            }
        },
        handleDeleteAttachment() {
            this.$emit('delete', this.path);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.attachment {
    min-height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .filename {
        flex: 1;
    }
    &:hover {
        font-weight: bold;
    }
}
</style>
