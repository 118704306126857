const claimMixin = {
    methods: {
        async createClaimHistoryEntry(currentUserId, change, status = null) {
            // entry status defaults to the change string if not specified
            let entryStatus = status || change;

            const userSnapshot = await this.$fire
                .doc(`/users/${currentUserId}`)
                .get();

            let userRole = 'Participant';
            switch (userSnapshot.get('role')) {
                case 1:
                case 2:
                    userRole = 'Admin';
                    break;
                case 3:
                    userRole = 'Coordinator';
                    break;
            }

            let changeMessage = change;
            switch (change) {
                case 'create':
                    changeMessage = 'Claim was created';
                    // created claims default to pending status unless overridden
                    entryStatus = status || 'pending';
                    break;
                case 'pending':
                    changeMessage = 'Claim was edited';
                    break;
                case 'approved':
                    changeMessage = `${userRole} approved claim`;
                    break;
                case 'rejected':
                    changeMessage = `${userRole} rejected claim`;
                    break;
                case 'attached':
                    changeMessage = `${userRole} added an attachment`;
                    break;
                case 'removed':
                    changeMessage = `${userRole} removed an attachment`;
                    break;
            }

            return {
                date: new Date(),
                user: currentUserId,
                user_name: userSnapshot.get('name') || '?',
                description: changeMessage,
                status: entryStatus,
            };
        },
    },
};

export default claimMixin;
