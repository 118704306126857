<template>
    <div class="file-info">
        <span class="filename">{{ filename }}</span>
        <span v-if="cancelled" class="error">Cancelled</span>
        <span v-else-if="error" class="error">Error uploading file</span>
        <el-progress v-else class="progress" :percentage="progress" />
        <i v-if="completed" class="cancel el-icon-circle-check" />
        <i
            v-else
            class="cancel el-icon-circle-close"
            @click="handleCancelUpload"
        />
    </div>
</template>

<script>
export default {
    name: 'uploaded-file',
    props: {
        upload: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            progress: 0,
            error: false,
            cancelled: false,
            completed: false,
        };
    },
    computed: {
        filename() {
            // return this.upload.snapshot?.metadata?.name;
            return this.upload.filename;
        },
    },
    watch: {
        upload: {
            handler(newval, oldval) {
                this.error = false;
                this.cancelled = false;
                this.completed = false;
                console.log('Register event handling on', newval, oldval);
                this.upload.on(
                    'state_changed',
                    (sp) => {
                        // progress
                        this.progress = Math.floor(
                            (sp.bytesTransferred / sp.totalBytes) * 100
                        );
                    },
                    (err) => {
                        // error
                        this.error = true;
                        this.$emit('error', this.upload);
                    },
                    () => {
                        // complete
                        this.progress = 100;
                        this.completed = true;
                        this.$emit(
                            'complete',
                            this.upload,
                            this.upload.snapshot.ref.fullPath
                        );
                    }
                );
            },
            immediate: true,
        },
    },
    methods: {
        handleCancelUpload() {
            if (!this.cancelled && !this.error) {
                console.log('Cancel upload');
                this.cancelled = true;
                this.upload.cancel();
                this.$emit('cancel', this.upload);
            } else {
                this.$emit('remove', this.upload);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.file-info {
    width: 100%;
    display: flex;
    align-items: center;
    .filename {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .progress {
        width: 200px;
    }
    .error {
        color: $red;
        margin-right: 10px;
    }
    .cancel {
        padding: 5px;
    }
}
</style>
