var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "claim-modal",
          attrs: { visible: _vm.show, "before-close": _vm.handleCloseClaim },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.selectedTab,
                callback: function ($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Claim details", name: "claim" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "auto-complete": "on",
                        "label-position": "left",
                        "label-width": "200px",
                      },
                    },
                    [
                      _vm.already_claimed
                        ? _c("el-alert", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              type: "error",
                              closable: false,
                              title: "Warning",
                              description:
                                "This invoice number has already been claimed against this provider.",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Support category", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "Choose category",
                                "value-key": "id",
                              },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            },
                            _vm._l(_vm.categories, function (c) {
                              return _c("el-option", {
                                key: c.id,
                                attrs: { label: c.name, value: c },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Date", required: "" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "timestamp",
                              format: "dd/MM/yyyy",
                              "prefix-icon": "-",
                            },
                            model: {
                              value: _vm.updated_claim.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.updated_claim, "date", $$v)
                              },
                              expression: "updated_claim.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Provider", required: "" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.updated_claim.provider,
                              callback: function ($$v) {
                                _vm.$set(_vm.updated_claim, "provider", $$v)
                              },
                              expression: "updated_claim.provider",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Invoice number" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.updated_claim.invoice_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updated_claim,
                                  "invoice_number",
                                  $$v
                                )
                              },
                              expression: "updated_claim.invoice_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.category
                        ? _c(
                            "div",
                            { staticClass: "budget-info" },
                            [
                              _vm.budget
                                ? [
                                    _c("span", [
                                      _vm._v(" Budget allocated: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.budget.budget_allocated
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("span", [
                                      _vm._v(" Budget spent: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.budget.budget_spent
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("span", [
                                      _vm._v(" Budget available: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.budget_available
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _c("div", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: true,
                                        expression: "true",
                                      },
                                    ],
                                    staticClass: "spinner",
                                    attrs: {
                                      "element-loading-background":
                                        "rgba(0, 0, 0, 0)",
                                    },
                                  }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Total amount", required: "" } },
                        [
                          _c("div", { staticClass: "el-form-item__content" }, [
                            _c(
                              "div",
                              { staticClass: "el-input" },
                              [
                                _c(
                                  "money",
                                  _vm._b(
                                    {
                                      staticClass: "el-input__inner money",
                                      model: {
                                        value: _vm.updated_claim.total,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updated_claim,
                                            "total",
                                            $$v
                                          )
                                        },
                                        expression: "updated_claim.total",
                                      },
                                    },
                                    "money",
                                    _vm.money,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                            _vm.category && _vm.category.allow_negative
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "10px",
                                      "margin-top": "-5px",
                                      display: "block",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Claims with a total amount higher than the available budget are allowed for this category. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c("el-alert", {
                        staticClass: "attachment-info",
                        attrs: {
                          type: "info",
                          "show-icon": "",
                          closable: false,
                          title:
                            "All attachments are visible\n                to the participant, and their coordinator(s).",
                          description: _vm.attachmentSaveMessage,
                        },
                      }),
                      _c("el-form-item", { attrs: { label: "Attachments" } }, [
                        _c(
                          "div",
                          { staticClass: "el-form-item__content" },
                          [
                            _vm.attachments.length
                              ? _c(
                                  "div",
                                  { staticClass: "attachments" },
                                  _vm._l(
                                    _vm.attachments,
                                    function (attachment) {
                                      return _c("attachment", {
                                        key: attachment.path,
                                        attrs: {
                                          name: attachment.name,
                                          path: attachment.path,
                                        },
                                        on: {
                                          delete: _vm.handleDeleteAttachment,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            _vm._l(_vm.uploads, function (file) {
                              return _c("uploaded-file", {
                                key: file.snapshot?.metadata?.md5Hash,
                                attrs: { upload: file },
                                on: {
                                  complete: _vm.handleUploadComplete,
                                  remove: _vm.handleUploadRemove,
                                  cancel: _vm.handleUploadCancel,
                                  error: _vm.handleUploadCancel,
                                },
                              })
                            }),
                            _c(
                              "div",
                              { staticClass: "attachment-footer" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "upload-button",
                                    attrs: { size: "mini" },
                                    on: { click: _vm.handleUploadAttachment },
                                  },
                                  [_vm._v(" Add attachments ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "attachment-warning" },
                                  [_vm._v(" Image (JPG/PNG/GIF) or PDF ")]
                                ),
                              ],
                              1
                            ),
                            _c("input", {
                              ref: "upload_attachment",
                              staticStyle: { display: "none" },
                              attrs: { type: "file", multiple: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.detectFile($event.target.files)
                                },
                              },
                            }),
                          ],
                          2
                        ),
                      ]),
                      !_vm.is_valid_claim
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading,
                                    disabled:
                                      !_vm.is_valid_form ||
                                      _vm.uploadsInProgress,
                                  },
                                  on: {
                                    click: () => {
                                      _vm.needsConfirmation
                                        ? (_vm.confirmation_visible = true)
                                        : _vm.createClaim()
                                    },
                                  },
                                },
                                [
                                  _vm.userRequiresApproval
                                    ? _c("span", [
                                        _vm._v(" Create & notify participant "),
                                      ])
                                    : _c("span", [_vm._v("Create")]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.userRequiresApproval &&
                              _vm.claimApprovalResolved
                                ? _c("el-alert", {
                                    staticClass: "approval-warning",
                                    attrs: {
                                      type: "warning",
                                      "show-icon": "",
                                      closable: false,
                                      title:
                                        "Note: updates to this claim will require participant\n                        approval again",
                                    },
                                  })
                                : !_vm.userRequiresApproval && _vm.claimRejected
                                ? _c("el-alert", {
                                    staticClass: "approval-warning",
                                    attrs: {
                                      type: "warning",
                                      "show-icon": "",
                                      closable: false,
                                      title:
                                        "Note: this claim was previously rejected; updating will automatically approve it",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "el-row",
                                {
                                  staticClass: "header",
                                  attrs: { gutter: 20 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            loading: _vm.loading,
                                            type: "danger",
                                            disabled: _vm.uploadsInProgress,
                                          },
                                          on: { click: _vm.handleDelete },
                                        },
                                        [_vm._v(" Delete ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            loading: _vm.loading,
                                            disabled:
                                              !_vm.is_valid_form ||
                                              _vm.uploadsInProgress,
                                          },
                                          on: {
                                            click: () => {
                                              _vm.needsConfirmation
                                                ? (_vm.confirmation_visible = true)
                                                : _vm.updateClaim()
                                            },
                                          },
                                        },
                                        [
                                          _vm.userRequiresApproval
                                            ? _c("span", [
                                                _vm._v(
                                                  " Update & notify participant "
                                                ),
                                              ])
                                            : _c("span", [_vm._v("Update")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.is_valid_claim
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: `Change history (${_vm.historyLength})`,
                        name: "history",
                      },
                    },
                    [
                      _vm.historyLength
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.claimHistory } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "date",
                                  label: "Date",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.date
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("dateformat")(
                                                        _vm._f(
                                                          "timestamp2Time"
                                                        )(
                                                          scope.row.date
                                                            .seconds ??
                                                            scope.row.date
                                                        ),
                                                        "MMM D, YYYY hh:mm a"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _c("span", [_vm._v("-")]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  584890920
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "user_name", label: "Name" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "description", label: "Change" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "status", width: "50" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.status === "pending"
                                            ? _c("i", {
                                                staticClass:
                                                  "status-icon pending el-icon-warning",
                                              })
                                            : _vm._e(),
                                          scope.row.status === "approved"
                                            ? _c("i", {
                                                staticClass:
                                                  "status-icon approved el-icon-circle-check",
                                              })
                                            : _vm._e(),
                                          scope.row.status === "rejected"
                                            ? _c("i", {
                                                staticClass:
                                                  "status-icon rejected el-icon-circle-close",
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2149335670
                                ),
                              }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "no-history" }, [
                            _vm._v(
                              " No history is currently available for this claim. "
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Warning",
            visible: _vm.confirmation_visible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.confirmation_visible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              " By " +
                _vm._s(_vm.is_valid_claim ? "updating" : "creating") +
                " this claim, this category will have a negative available budget. "
            ),
            _c("br"),
            _vm._v(" Are you sure you want to continue? "),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.confirmation_visible = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: () => {
                      _vm.is_valid_claim ? _vm.updateClaim() : _vm.createClaim()
                    },
                  },
                },
                [_vm._v(" Yes, continue ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }