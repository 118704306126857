var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-info" },
    [
      _c("span", { staticClass: "filename" }, [_vm._v(_vm._s(_vm.filename))]),
      _vm.cancelled
        ? _c("span", { staticClass: "error" }, [_vm._v("Cancelled")])
        : _vm.error
        ? _c("span", { staticClass: "error" }, [_vm._v("Error uploading file")])
        : _c("el-progress", {
            staticClass: "progress",
            attrs: { percentage: _vm.progress },
          }),
      _vm.completed
        ? _c("i", { staticClass: "cancel el-icon-circle-check" })
        : _c("i", {
            staticClass: "cancel el-icon-circle-close",
            on: { click: _vm.handleCancelUpload },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }