<template>
    <div>
        <el-dialog
            :visible.sync="show"
            :before-close="handleCloseClaim"
            class="claim-modal"
        >
            <el-tabs v-model="selectedTab" type="card">
                <el-tab-pane label="Claim details" name="claim">
                    <el-form
                        auto-complete="on"
                        label-position="left"
                        label-width="200px"
                    >
                        <el-alert
                            v-if="already_claimed"
                            type="error"
                            :closable="false"
                            title="Warning"
                            description="This invoice number has already been claimed against this provider."
                            style="margin-bottom: 10px"
                        />

                        <el-form-item label="Support category" required>
                            <el-select
                                v-model="category"
                                placeholder="Choose category"
                                value-key="id"
                            >
                                <el-option
                                    v-for="c in categories"
                                    :key="c.id"
                                    :label="c.name"
                                    :value="c"
                                />
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Date" required>
                            <el-date-picker
                                v-model="updated_claim.date"
                                value-format="timestamp"
                                format="dd/MM/yyyy"
                                prefix-icon="-"
                            />
                        </el-form-item>

                        <el-form-item label="Provider" required>
                            <el-input v-model="updated_claim.provider" />
                        </el-form-item>

                        <el-form-item label="Invoice number">
                            <el-input v-model="updated_claim.invoice_number" />
                        </el-form-item>

                        <div v-if="category" class="budget-info">
                            <template v-if="budget">
                                <span>
                                    Budget allocated:
                                    <strong>
                                        {{ budget.budget_allocated | currency }}
                                    </strong>
                                </span>
                                <span>
                                    Budget spent:
                                    <strong>
                                        {{ budget.budget_spent | currency }}
                                    </strong>
                                </span>
                                <span>
                                    Budget available:
                                    <strong>
                                        {{ budget_available | currency }}
                                    </strong>
                                </span>
                            </template>
                            <div
                                v-else
                                v-loading="true"
                                class="spinner"
                                element-loading-background="rgba(0, 0, 0, 0)"
                            />
                        </div>

                        <el-form-item label="Total amount" required>
                            <div class="el-form-item__content">
                                <div class="el-input">
                                    <money
                                        v-model="updated_claim.total"
                                        v-bind="money"
                                        class="el-input__inner money"
                                    />
                                </div>
                                <span
                                    v-if="category && category.allow_negative"
                                    style="
                                        font-size: 10px;
                                        margin-top: -5px;
                                        display: block;
                                    "
                                >
                                    Claims with a total amount higher than the
                                    available budget are allowed for this
                                    category.
                                </span>
                            </div>
                        </el-form-item>

                        <el-alert
                            type="info"
                            show-icon
                            :closable="false"
                            class="attachment-info"
                            title="All attachments are visible
                    to the participant, and their coordinator(s)."
                            :description="attachmentSaveMessage"
                        />

                        <el-form-item label="Attachments">
                            <div class="el-form-item__content">
                                <div
                                    v-if="attachments.length"
                                    class="attachments"
                                >
                                    <attachment
                                        v-for="attachment of attachments"
                                        :key="attachment.path"
                                        :name="attachment.name"
                                        :path="attachment.path"
                                        @delete="handleDeleteAttachment"
                                    />
                                </div>

                                <uploaded-file
                                    v-for="file of uploads"
                                    :key="file.snapshot?.metadata?.md5Hash"
                                    :upload="file"
                                    @complete="handleUploadComplete"
                                    @remove="handleUploadRemove"
                                    @cancel="handleUploadCancel"
                                    @error="handleUploadCancel"
                                />
                                <div class="attachment-footer">
                                    <el-button
                                        size="mini"
                                        class="upload-button"
                                        @click="handleUploadAttachment"
                                    >
                                        Add attachments
                                    </el-button>
                                    <div class="attachment-warning">
                                        Image (JPG/PNG/GIF) or PDF
                                    </div>
                                </div>
                                <input
                                    ref="upload_attachment"
                                    type="file"
                                    style="display: none"
                                    multiple
                                    @change="detectFile($event.target.files)"
                                />
                            </div>
                        </el-form-item>

                        <div v-if="!is_valid_claim">
                            <el-button
                                type="primary"
                                style="width: 100%"
                                :loading="loading"
                                :disabled="!is_valid_form || uploadsInProgress"
                                @click="
                                    () => {
                                        needsConfirmation
                                            ? (confirmation_visible = true)
                                            : createClaim();
                                    }
                                "
                            >
                                <span v-if="userRequiresApproval">
                                    Create & notify participant
                                </span>
                                <span v-else>Create</span>
                            </el-button>
                        </div>
                        <div v-else>
                            <el-alert
                                v-if="
                                    userRequiresApproval &&
                                    claimApprovalResolved
                                "
                                type="warning"
                                show-icon
                                :closable="false"
                                class="approval-warning"
                                title="Note: updates to this claim will require participant
                            approval again"
                            />
                            <el-alert
                                v-else-if="
                                    !userRequiresApproval && claimRejected
                                "
                                type="warning"
                                show-icon
                                :closable="false"
                                class="approval-warning"
                                title="Note: this claim was previously rejected; updating will automatically approve it"
                            />
                            <el-row :gutter="20" class="header">
                                <el-col :span="12">
                                    <el-button
                                        :loading="loading"
                                        type="danger"
                                        :disabled="uploadsInProgress"
                                        style="width: 100%"
                                        @click="handleDelete"
                                    >
                                        Delete
                                    </el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button
                                        :loading="loading"
                                        :disabled="
                                            !is_valid_form || uploadsInProgress
                                        "
                                        style="width: 100%"
                                        @click="
                                            () => {
                                                needsConfirmation
                                                    ? (confirmation_visible = true)
                                                    : updateClaim();
                                            }
                                        "
                                    >
                                        <span v-if="userRequiresApproval">
                                            Update & notify participant
                                        </span>
                                        <span v-else>Update</span>
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane
                    v-if="is_valid_claim"
                    :label="`Change history (${historyLength})`"
                    name="history"
                >
                    <el-table v-if="historyLength" :data="claimHistory">
                        <el-table-column prop="date" label="Date" width="200">
                            <template slot-scope="scope">
                                {{
                                    (scope.row.date.seconds ?? scope.row.date)
                                        | timestamp2Time
                                        | dateformat('MMM D, YYYY hh:mm a')
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="user_name" label="Name" />
                        <el-table-column prop="description" label="Change" />
                        <el-table-column prop="status" width="50">
                            <template slot-scope="scope">
                                <i
                                    v-if="scope.row.status === 'pending'"
                                    class="status-icon pending el-icon-warning"
                                />
                                <i
                                    v-if="scope.row.status === 'approved'"
                                    class="status-icon approved el-icon-circle-check"
                                />
                                <i
                                    v-if="scope.row.status === 'rejected'"
                                    class="status-icon rejected el-icon-circle-close"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-else class="no-history">
                        No history is currently available for this claim.
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>

        <el-dialog
            title="Warning"
            :visible.sync="confirmation_visible"
            width="30%"
        >
            <span>
                By {{ is_valid_claim ? 'updating' : 'creating' }} this claim,
                this category will have a negative available budget.
                <br />
                Are you sure you want to continue?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmation_visible = false">
                    Cancel
                </el-button>
                <el-button
                    type="danger"
                    @click="
                        () => {
                            is_valid_claim ? updateClaim() : createClaim();
                        }
                    "
                >
                    Yes, continue
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {Money} from 'v-money';
import firebase from 'firebase/app';
import _ from 'lodash';
import moment from 'moment';

import * as Sentry from '@sentry/browser';
import UploadedFile from '@/views/components/UploadedFile';
import Attachment from '@/views/components/Attachment';

import {generateUUID} from '@/utils/firebase';
import axios from 'axios';
import ClaimMixin from '@/mixins/claim.mixin';

export default {
    name: 'claim-modal',
    components: {
        Money,
        Attachment,
        UploadedFile,
    },
    mixins: [ClaimMixin],
    props: {
        categories: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            show: false,
            selectedTab: 'claim',

            already_claimed: false,

            original_category_id: null,
            original_budget_id: null,

            category: null,
            budget_id: null,
            claim: null,

            budget: null,
            claims: null, // existing claims

            updated_claim: {
                date: null,
                provider: null,
                total: 0,
                invoice_number: null,
            },

            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
            },

            attachments: [],
            uploads: [],
            newID: null,
            created: false,
            confirmation_visible: false,
        };
    },
    computed: {
        budget_available() {
            return this.budget.budget_allocated - this.budget.budget_spent;
        },
        is_valid_claim() {
            return this.claim && this.claim.id != null;
        },
        is_valid_claim_amount() {
            // Some categories are flagged to allow for negative claims to be added
            // If a flagged category is selected, the total has to be different from 0
            if (this.category && this.category.allow_negative) {
                return this.updated_claim.total != 0;
            }
            // Otherwise it has to be a positive value - not negative allowed
            else {
                return (
                    this.updated_claim.total <=
                    this.budget_available + (this.claim?.total ?? 0)
                );
            }
        },
        is_valid_form() {
            return (
                this.updated_claim &&
                this.updated_claim.provider != null &&
                this.updated_claim.date != null &&
                this.updated_claim.total > 0 &&
                this.budget &&
                this.is_valid_claim_amount
            );
        },
        userRequiresApproval() {
            return this.user?.require_approval || false;
        },
        claimApprovalResolved() {
            return (
                this.claim.approval_status === 'approved' ||
                this.claim.approval_status === 'rejected'
            );
        },
        claimRejected() {
            return this.claim.approval_status === 'rejected';
        },
        currentUser() {
            return firebase.auth().currentUser;
        },
        uploadsInProgress() {
            return !!this.uploads?.length;
        },
        claimHistory() {
            const history = this.claim?.history || [];
            return [...history].sort((a, b) => b.date - a.date);
        },
        historyLength() {
            return this.claim?.history?.length || 0;
        },
        attachmentSaveMessage() {
            if (!this.is_valid_claim) return null; // don't display this message for new claim
            let message =
                'Addition or removal of attachments is automatically saved.';
            if (this.userRequiresApproval) {
                message += ' Click update when ready to notify participant.';
            }
            return message;
        },
        needsConfirmation() {
            if (!this.category.allow_negative) return false;

            if (
                this.updated_claim.total >=
                this.budget_available + (this.claim?.total ?? 0)
            )
                return true;

            return false;
        },
    },
    watch: {
        user: function (val) {
            this.setUser(val);
        },
        category: function (val) {
            this.already_claimed = false;
            if (val) {
                this.budget_id = this.data
                    .find((item) => {
                        return item.category === `categories/${val.id}`;
                    })
                    .budget.replace('budgets/', '');
            } else {
                this.budget_id = null;
                this.budget = null;
            }
        },
        budget_id: function (val) {
            if (val != null) {
                this.$bind('budget', this.$fire.doc(`budgets/${val}`), {
                    maxRefDepth: 1,
                }).then((budget) => {
                    this.claims = budget.claims;
                });
            }
        },

        'updated_claim.invoice_number': function () {
            this.already_claimed = false;
        },
        'updated_claim.provider': function () {
            this.already_claimed = false;
        },
    },
    mounted() {
        this.$bus.$on('claim-modal', (data) => {
            this.selectedTab = 'claim';
            this.claim = data.claim;

            // Data needed to move/update claim
            this.category = data.category;
            if (data.budget) {
                this.budget_id = data.budget.id;
                // Data needed to show budget available
                this.budget = data.budget;
                this.claims = data.budget.claims; // these deep values may be lost in later operations, grab them now
            }

            // Editing claim
            if (data.claim) {
                this.original_category_id = data.category.id;
                this.original_budget_id = data.budget.id;

                this.updated_claim = Object.assign({}, this.claim);
                this.updated_claim.id = this.claim.id;
                this.updated_claim.date = data.claim.date.seconds * 1000;
                this.attachments = data.claim.attachments ?? [];
                if (!this.claim.budget) {
                    this.updated_claim.budget = this.$fire.doc(
                        `budgets/${this.budget.id}`
                    );
                }
            }
            // Creating claim
            else {
                this.created = false;
                this.newID = generateUUID();
                this.attachments = [];

                this.updated_claim = {
                    date: new Date(),
                    provider: null,
                    invoice_number: null,
                    total: 0,
                    budget: this.budget
                        ? this.$fire.doc(`budgets/${this.budget.id}`)
                        : null,
                };
            }

            this.setUser(this.user);

            this.show = true;
        });
    },
    beforeDestroy() {
        this.$bus.$off('claim-modal');
    },
    methods: {
        setUser(user) {
            if (user != null) {
                this.updated_claim.user = this.$fire.doc(`users/${user.id}`);
            }
        },
        async createClaim() {
            this.loading = true;
            this.confirmation_visible = false;

            try {
                // check for combined invoice# and provider
                this.checkInvoiceNumber();

                // trim provider & invoice_number before duplicate check
                this.updated_claim.provider =
                    this.updated_claim.provider.trim();
                this.updated_claim.invoice_number =
                    this.updated_claim.invoice_number?.trim() || null;

                if (await this.isDuplicate(this.updated_claim)) {
                    this.already_claimed = true;
                    this.loading = false;
                    return;
                }

                if (!(this.updated_claim.date instanceof Date)) {
                    // Parse claim date from milliseconds to Date
                    this.updated_claim.date = new Date(
                        parseInt(this.updated_claim.date)
                    );
                }

                if (this.userRequiresApproval) {
                    this.updated_claim.approval_status = 'pending';
                    // set approval deadline to 7 days from now
                    this.updated_claim.approval_due = moment()
                        .add(7, 'days')
                        .toDate();
                } else {
                    delete this.updated_claim.approval_due;
                }

                // Add creation details to claim
                this.updated_claim.created_at = new Date();
                this.updated_claim.created_by = this.$fire.doc(
                    `users/${this.currentUser.uid}`
                );
                const historyEntry = await this.createClaimHistoryEntry(
                    this.currentUser.uid,
                    'create'
                );
                this.updated_claim.history = [historyEntry];

                // Add uploaded attachments to claim
                this.updated_claim.attachments = this.attachments;

                // Add reverse reference to budget
                this.updated_claim.budget = this.$fire.doc(
                    `budgets/${this.budget_id}`
                );

                // Create claim object using newID
                await this.$fire
                    .collection('claims')
                    .doc(this.newID)
                    .set(this.updated_claim);

                // Add claim reference to budget object
                // Update available budget
                await this.$fire.doc(`budgets/${this.budget_id}`).update({
                    claims: firebase.firestore.FieldValue.arrayUnion(
                        this.$fire.doc(`claims/${this.newID}`)
                    ),
                    budget_spent: firebase.firestore.FieldValue.increment(
                        this.updated_claim.total
                    ),
                });

                console.log('Created claim', this.newID);

                if (this.user.require_approval) {
                    // send notification for new claim
                    this.sendNotification(this.newID);
                }

                this.created = true;
            } catch (e) {
                console.log('Error creating claim', e);
                Sentry.captureException(e);
            } finally {
                this.loading = false;
                if (!this.already_claimed) {
                    this.show = false;
                }
            }
        },
        async updateClaim() {
            this.loading = true;
            this.confirmation_visible = false;

            try {
                if (await this.isDuplicate(this.updated_claim)) {
                    this.already_claimed = true;
                    this.loading = false;
                    return;
                }

                this.updated_claim.date = new Date(
                    parseInt(this.updated_claim.date)
                );
                if (this.user.require_approval) {
                    // reset approval status
                    this.updated_claim.approval_status = 'pending';
                    // reset approval deadline to 7 days from now
                    this.updated_claim.approval_due = moment()
                        .add(7, 'days')
                        .toDate();
                } else {
                    this.updated_claim.approval_due =
                        firebase.firestore.FieldValue.delete();
                    this.updated_claim.approval_status =
                        firebase.firestore.FieldValue.delete();
                }

                // check for combined invoice# and provider
                this.checkInvoiceNumber();

                // re-save created_by as a DocumentReference
                if (this.updated_claim.created_by) {
                    this.updated_claim.created_by = this.$fire.doc(
                        this.updated_claim.created_by
                    );
                }

                // Update attachments in claim
                this.updated_claim.attachments = this.attachments;

                // add edit to history
                const historyEntry = await this.createClaimHistoryEntry(
                    this.currentUser.uid,
                    'pending'
                );
                this.updated_claim.history =
                    firebase.firestore.FieldValue.arrayUnion(historyEntry);

                // remove temp ID
                delete this.updated_claim.id;

                // perform all updates in one transaction
                await this.$fire.runTransaction(async (transaction) => {
                    const claimDoc = this.$fire.doc(`claims/${this.claim.id}`);
                    const srcBudgetDoc = this.$fire.doc(
                        `budgets/${this.original_budget_id}`
                    );
                    const destBudgetDoc = this.$fire.doc(
                        `budgets/${this.budget_id}`
                    );

                    this.updated_claim.budget = destBudgetDoc;

                    // Update claim object
                    await transaction.update(claimDoc, this.updated_claim);

                    // Category has been changed - need to move claim to new budget
                    if (this.original_budget_id !== this.budget_id) {
                        // Remove claim from original budget and reduce budget_spent
                        const budgetChange =
                            this.claim.approval_status === 'rejected'
                                ? 0
                                : -this.claim.total;
                        await transaction.update(srcBudgetDoc, {
                            budget_spent:
                                firebase.firestore.FieldValue.increment(
                                    budgetChange
                                ),
                            claims: firebase.firestore.FieldValue.arrayRemove(
                                claimDoc
                            ),
                        });

                        // Add reference claim to new budget and adjust budget_spent
                        await transaction.update(destBudgetDoc, {
                            budget_spent:
                                firebase.firestore.FieldValue.increment(
                                    this.updated_claim.total
                                ),
                            claims: firebase.firestore.FieldValue.arrayUnion(
                                claimDoc
                            ),
                        });
                    }
                    // Category is the same as original
                    else {
                        // if claim had been rejected, its full amount needs to be applied to budget
                        if (this.claim.approval_status === 'rejected') {
                            await transaction.update(destBudgetDoc, {
                                budget_spent:
                                    firebase.firestore.FieldValue.increment(
                                        this.claim.total
                                    ),
                            });
                        }
                        // Update available budget if claim total changed
                        else if (
                            this.updated_claim.total !== this.claim.total
                        ) {
                            await transaction.update(destBudgetDoc, {
                                budget_spent:
                                    firebase.firestore.FieldValue.increment(
                                        -(
                                            this.claim.total -
                                            this.updated_claim.total
                                        )
                                    ),
                            });
                        }
                    }
                });

                if (this.user.require_approval) {
                    // send notification for new claim
                    this.sendNotification(this.claim.id);
                }
            } catch (e) {
                this.$notify.error({
                    title: 'Error',
                    message:
                        'Unable to save claim. Please check the details and try again.',
                });
                console.log('Error updating claim:', e);
                Sentry.captureException(e);
            } finally {
                this.loading = false;
                if (!this.already_claimed) {
                    this.show = false;
                }
            }
        },
        async handleDelete() {
            this.loading = true;

            try {
                // Delete any attachments
                this.attachments.forEach((attachment) => {
                    this.deleteAttachment(attachment.path);
                });

                const budgetChange =
                    this.claim.approval_status === 'rejected'
                        ? 0
                        : -this.claim.total;

                // Remove claim ref from budget and recalculate
                await this.$fire.doc(`budgets/${this.budget_id}`).update({
                    claims: firebase.firestore.FieldValue.arrayRemove(
                        this.$fire.doc(`claims/${this.claim.id}`)
                    ),
                    budget_spent:
                        firebase.firestore.FieldValue.increment(budgetChange),
                });

                // Delete claim
                await this.$fire.doc(`claims/${this.claim.id}`).delete();
            } catch (e) {
                console.log('Error deleting claim', e);
                Sentry.captureException(e);
            } finally {
                this.loading = false;
                this.show = false;
            }
        },
        checkInvoiceNumber() {
            var provider = this.updated_claim.provider;
            var invoice_number = this.updated_claim.invoice_number;

            if (!invoice_number && provider && provider.includes(' - inv ')) {
                const split = provider.split(' - inv ');
                provider = split[0];
                invoice_number = split[1];
            }

            this.updated_claim.provider = provider;
            this.updated_claim.invoice_number = invoice_number;
        },
        async isDuplicate(claim) {
            // check if invoice# & provider combination is unique
            // (check against common variations as best we can)
            const provider = [
                claim.provider,
                claim.provider.toUpperCase(),
                claim.provider.toLowerCase(),
                this.$options.filters.titleCase(claim.provider),
            ];

            // check claims for this budget:
            const match = _.find(this.claims, (existingClaim) => {
                if (!existingClaim) {
                    // empty claim detected
                    if (this.budget_id) {
                        Sentry.captureMessage(
                            `Empty claim found for budget ${this.budget_id}`
                        );
                        return false;
                    }
                }
                if (claim.id && claim.id === existingClaim.id) {
                    // same claim, not a duplicate
                    return false;
                } else {
                    if (!existingClaim.invoice_number) {
                        // without invoice number, can't consider a duplicate
                        return false;
                    } else {
                        // duplicate if provider matches, claim has an invoice#, and invoice# matches
                        return (
                            provider.includes(existingClaim.provider) &&
                            claim.invoice_number &&
                            claim.invoice_number.toLowerCase() ===
                                existingClaim.invoice_number.toLowerCase()
                        );
                    }
                }
            });

            return !!match;
        },

        handleUploadAttachment() {
            this.$refs.upload_attachment.click();
        },

        endsWithAny(value, ends) {
            let result = false;
            result = ends.some((element) => {
                return value.endsWith(element);
            });
            return result;
        },

        detectFile(files) {
            Array.from(Array(files.length).keys()).map((x) => {
                const file = files[x];
                // only allow images (JPG, PNG, GIF?) and PDF
                if (
                    file.type === 'application/pdf' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/gif' ||
                    this.endsWithAny(file.name, [
                        '.pdf',
                        '.jpg',
                        '.jpeg',
                        '.png',
                        '.gif',
                    ])
                ) {
                    this.upload(file);
                } else {
                    this.$notify.error({
                        title: 'Unsupported file type',
                        message: file.name,
                    });
                }
            });
            this.$refs.upload_attachment.value = null;
        },
        upload(file) {
            const claim_id = this.claim?.id ?? this.newID;
            const uploadTask = this.$storage
                .child(`${this.user.id}/${claim_id}/${file.name}`)
                .put(file);

            // add filename to uploadTask for convenience
            uploadTask.filename = file.name;

            this.uploads.push(uploadTask);
        },
        removeUploadTask(upload) {
            this.uploads = this.uploads.filter((item) => item !== upload);
        },

        async handleUploadComplete(upload, path) {
            // add attachment to claim
            this.attachments.push({name: upload.filename, path});
            if (this.is_valid_claim) {
                // add to change history
                const historyEntry = await this.createClaimHistoryEntry(
                    this.currentUser.uid,
                    'attached',
                    this.claim.approval_status
                );
                this.claim.history.push(historyEntry);
                // update existing claim's attachments immediately
                await this.$fire.doc(`claims/${this.claim.id}`).update({
                    attachments: this.attachments,
                    history:
                        firebase.firestore.FieldValue.arrayUnion(historyEntry),
                });
            }
            // remove upload task from queue
            this.removeUploadTask(upload);
        },
        handleUploadRemove(upload) {
            // remove upload task from queue
            this.removeUploadTask(upload);
        },
        handleUploadCancel(upload) {
            // remove upload task from queue after a delay
            setTimeout(() => {
                this.removeUploadTask(upload);
            }, 3000);
        },

        deleteAttachment(path) {
            this.$storage
                .child(path)
                .delete()
                .catch((e) => {
                    console.log("Couldn't delete attachment:", e);
                });
        },

        async handleDeleteAttachment(path) {
            // delete file on server
            this.deleteAttachment(path);
            // remove attachment from claim
            this.attachments = this.attachments.filter(
                (attachment) => attachment.path !== path
            );
            if (this.is_valid_claim) {
                // add to change history
                const historyEntry = await this.createClaimHistoryEntry(
                    this.currentUser.uid,
                    'removed',
                    this.claim.approval_status
                );
                this.claim.history.push(historyEntry);
                // update existing claim's attachments immediately
                await this.$fire.doc(`claims/${this.claim.id}`).update({
                    attachments: this.attachments,
                    history:
                        firebase.firestore.FieldValue.arrayUnion(historyEntry),
                });
            }
        },

        handleCloseClaim(done) {
            // if uploads are still in progress, do not close dialog
            if (!this.uploads.length) {
                // if cancelling a new claim, delete any files that were uploaded
                if (!this.is_valid_claim && !this.created) {
                    this.attachments.forEach((attachment) => {
                        this.deleteAttachment(attachment.path);
                    });
                    this.attachments = [];
                }
                done();
            }
        },

        async sendNotification(claim_id) {
            const token = await firebase.auth().currentUser.getIdToken();
            const data = {claim_id};
            await axios.post(
                `${process.env.VUE_APP_BASE_URI}/notification`,
                data,
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            );
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
@import '../../assets/scss/vars';
.el-dialog {
    border-radius: 10px;
    overflow: hidden;

    .el-dialog__body {
        padding-top: 0;
    }

    label {
        display: block;
        margin-bottom: 10px;
    }

    .el-select {
        width: 100%;
    }

    .el-date-editor {
        width: 100%;
    }

    .money {
        font-family: 'Rubik';
    }

    .budget-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: $black;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 20px;
        margin-left: 200px;

        span {
            color: white;
            padding: 4px 0;
        }
    }

    .attachments {
        border-bottom: 1px solid $grey;
    }

    .no-history {
        padding: 20px;
        text-align: center;
    }

    .status-icon {
        font-size: 20px;
        &.pending {
            color: $yellow;
        }
        &.rejected {
            color: $red;
        }
        &.approved {
            color: $green;
        }
    }

    .attachment-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        .attachment-warning {
            font-size: 12px;
            line-height: initial;
            text-align: right;
        }
    }
    .approval-warning {
        margin-bottom: 20px;
    }

    .attachment-info {
        margin-bottom: 10px;
        .el-alert__title {
            font-weight: normal !important;
        }
    }
}
</style>
