var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "attachment",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleOpenAttachment.apply(null, arguments)
        },
      },
    },
    [
      _c("span", { staticClass: "filename" }, [
        _vm.loading
          ? _c("i", { staticClass: "el-icon-loading" })
          : _c("i", { staticClass: "el-icon-document" }),
        _vm._v(" " + _vm._s(_vm.name) + " "),
      ]),
      _c(
        "el-popconfirm",
        {
          attrs: { title: "Delete this attachment?" },
          on: { confirm: _vm.handleDeleteAttachment },
        },
        [
          !_vm.readonly && _vm.is_superadmin
            ? _c("el-button", {
                attrs: {
                  slot: "reference",
                  type: "danger",
                  size: "mini",
                  icon: "el-icon-delete",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                slot: "reference",
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }